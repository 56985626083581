import _ from 'lodash'
import nodeDefinitionTypeMap from '../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import { calcScoreContribution } from './product'

export const nodeHasModelChangeItem = (node, modelChangeItemId) => {
  return _.find(node.model_change, _mc => _mc.model_change_item_id === modelChangeItemId) !== undefined
}

export const getNodeModelChangeData = (node, modelChangeItemId) => {
  return _.find(node.model_change, _mc => _mc.model_change_item_id === modelChangeItemId)
}

export const initModelChangeDataToNode = (node, modelChangeItemId) => {
  node.model_change = node.model_change ? node.model_change : []

  if (!nodeHasModelChangeItem(node, modelChangeItemId)) {
    node.model_change.push({
      model_change_item_id: modelChangeItemId,
    })
  }
}

export const updateModelChangeData = (node, modelChangeItemId, data = {}) => {
  const modelChangeData = getNodeModelChangeData(node, modelChangeItemId)

  if (modelChangeData) {
    _.each(data, (value, key) => {
      modelChangeData[key] = value
    })
  }
}

export const hasChildrenCriterionModelChange = (template, nodeDefinition, modelChangeData, modelChangeItemId) => {
  if (!modelChangeItemId || !modelChangeData?.[modelChangeItemId] || !nodeDefinition?.id || !template) return false

  const childrenCriterionNodeDefinitions = _.filter(
    template.node_definitions,
    _nd =>
      _nd._left > nodeDefinition._left &&
      _nd._right < nodeDefinition._right &&
      _nd._right - _nd._left === 1 &&
      _nd.type === nodeDefinitionTypeMap.criterion
  )

  if (!childrenCriterionNodeDefinitions || childrenCriterionNodeDefinitions.length === 0) return false

  return childrenCriterionNodeDefinitions.some(nodeDef => modelChangeData[modelChangeItemId].includes(nodeDef.id))
}

export const hasChildrenCriterionAnyModelChange = (template, nodeDefinition, modelChangeData) => {
  if (!nodeDefinition?.id || !template) return false

  const childrenCriterionNodeDefinitions = _.filter(
    template.node_definitions,
    _nd =>
      _nd._left > nodeDefinition._left &&
      _nd._right < nodeDefinition._right &&
      _nd._right - _nd._left === 1 &&
      _nd.type === nodeDefinitionTypeMap.criterion
  )

  if (!childrenCriterionNodeDefinitions || childrenCriterionNodeDefinitions.length === 0 || !modelChangeData)
    return false

  return childrenCriterionNodeDefinitions?.some(nodeDef =>
    Object.values(modelChangeData).some(modelChangeItemCriterions => modelChangeItemCriterions?.includes(nodeDef.id))
  )
}

/**
 * Calc product score with model change tweaks. This is based on a legacy algorithm.
 */
export const calculateProductScoreWithModelChange = (
  product,
  template,
  modelChangeItemId,
  enabledNodeDefinitionIds = [], // There are only criteria ids
  calculatedWeights
) => {
  const nodeDefinitions = _.keyBy(template.node_definitions, 'id')
  const productNodes = _.keyBy(product.nodes, 'node_definition_id')

  // Init every family node with model change data
  _.each(productNodes, node => {
    const { type } = nodeDefinitions[node.node_definition_id]
    if (type !== nodeDefinitionTypeMap.family) return

    // Assign and not toggle because i don't want to remove the informations about model change scores, just update them
    initModelChangeDataToNode(node, modelChangeItemId)
    updateModelChangeData(node, modelChangeItemId, { score: 0 })
  })

  // Get all enabled node definitions
  const enabledNodeDefinitions = {}
  enabledNodeDefinitionIds.forEach(id => {
    // Add the enabled node definition
    enabledNodeDefinitions[id] = nodeDefinitions[id]

    // Add all the ancestors of the enabled node definition
    Object.values(nodeDefinitions).forEach(_nd => {
      if (_nd._left < nodeDefinitions[id]._left && _nd._right > nodeDefinitions[id]._right) {
        enabledNodeDefinitions[_nd.id] = _nd
      }
    })
  })

  // Calculate score contribution for each family node
  product.nodes = _.map(productNodes, node => {
    if (!(node.node_definition_id in enabledNodeDefinitions)) return node
    if (enabledNodeDefinitions[node.node_definition_id].type !== nodeDefinitionTypeMap.family) return node

    const scoreContribution = calcScoreContribution(
      calculatedWeights,
      enabledNodeDefinitions[node.node_definition_id],
      enabledNodeDefinitions,
      product
    )

    updateModelChangeData(node, modelChangeItemId, {
      score: scoreContribution,
    })

    return node
  })

  return product
}
